// Generated by Framer (16060ad)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {VXUp1MFrH: {hover: true}};

const cycleOrder = ["VXUp1MFrH"];

const variantClassNames = {VXUp1MFrH: "framer-v-1b83znb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "VXUp1MFrH", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VXUp1MFrH", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-THdiw", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1b83znb", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VXUp1MFrH"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"VXUp1MFrH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-880w1t"} data-framer-name={"home_logo_sbb"} fill={"black"} intrinsicHeight={70} intrinsicWidth={160} layoutDependency={layoutDependency} layoutId={"BkS5jlZTU"} style={{opacity: 0.5}} svg={"<svg data-name=\"Component 840 – 1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"160\" height=\"70\"><path data-name=\"Path 2991\" d=\"M67.106 48.438h7.5L64.512 38.112h12.534v10.326h5.92V38.112h12.53l-10.1 10.326h7.5l12.858-13.416-12.852-13.46h-7.5l10.1 10.37H82.966v-10.37h-5.92v10.37H64.512l10.094-10.37h-7.5l-12.86 13.462 12.86 13.414\" fill=\"#666\" fill-rule=\"evenodd\"/></svg>"} transition={transition} variants={{"VXUp1MFrH-hover": {opacity: 1}}} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-THdiw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-THdiw .framer-zf0gvb { display: block; }", ".framer-THdiw .framer-1b83znb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 160px; }", ".framer-THdiw .framer-880w1t { aspect-ratio: 2.2857142857142856 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); position: relative; width: 100%; }", ".framer-THdiw .framer-v-1b83znb .framer-1b83znb { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-THdiw .framer-1b83znb { gap: 0px; } .framer-THdiw .framer-1b83znb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-THdiw .framer-1b83znb > :first-child { margin-left: 0px; } .framer-THdiw .framer-1b83znb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"DLCZzPlek":{"layout":["fixed","auto"]}}}
 */
const FramerQi1qFxeZV: React.ComponentType<Props> = withCSS(Component, css, "framer-THdiw") as typeof Component;
export default FramerQi1qFxeZV;

FramerQi1qFxeZV.displayName = "logo-sbb";

FramerQi1qFxeZV.defaultProps = {height: 70, width: 160};

addFonts(FramerQi1qFxeZV, [])